<template>
  <div class="contain_view">
    <div class="head_btn">
      <van-tabs
        type="card"
        class="tab_card"
        v-model="activeIndex"
        @click="switchTab"
      >
        <van-tab title="属性"></van-tab>
        <van-tab title="布局"></van-tab>
      </van-tabs>
      <van-button type="info" @click="saveTemplate">保存</van-button>
    </div>
    <!-- 属性 -->
    <div v-show="activeIndex === 0">
      <div class="line flex">
        <div>模版名称</div>
        <input
          class="name_input"
          type="text"
          placeholder="请输入模板名称"
          v-model="templateName"
        />
      </div>
      <div class="flex_align_center line">
        <div>标签宽高</div>
        <input
          class="lab_input"
          v-model="template_width"
          type="text"
          placeholder="30"
        />
        <div>mm</div>
        <div class="lab_unit">X</div>
        <input
          class="lab_input"
          v-model="template_heith"
          type="text"
          placeholder="30"
        />
        <div>mm</div>
      </div>
      <div class="flex_align_center line">
        <div>整体偏移</div>
        <input
          class="lab_input"
          v-model="template_offx"
          type="text"
          placeholder="0"
        />
        <div>mm</div>
        <div class="lab_unit">X</div>
        <input
          class="lab_input"
          v-model="template_offy"
          type="text"
          placeholder="0"
        />
        <div>mm</div>
      </div>
    </div>

    <!-- 布局 -->
    <div v-show="activeIndex === 1">
      <!-- 画布大小 -->
      <div class="canvas_size flex_just_center">
        <van-button plain type="info" size="small" @click="setzoom(true)"
          >放大</van-button
        >
        <div class="size_rate">{{ canvasScaleRate }}%</div>
        <van-button plain type="info" size="small" @click="setzoom(false)"
          >缩小</van-button
        >
      </div>
      <!-- canvas 布局区域 -->
      <div class="layout_content flex">
        <!--canvas区域 -->
        <div class="canvas_border" ref="canvas_border" :style="canvasStyle">
          <canvas
            id="canvas-box"
            ref="canvas"
            class="canvas"
            :style="activeIndex === 0 ? 'display:none' : ''"
          ></canvas>
        </div>

        <!-- 右侧区域 -->
        <div class="option_btns">
          <!-- <div class="option" draggable="true" ondragstart="onDragstart('circle')"> -->
          <div class="option" draggable="true" @click="onDrag('text')">
            <img src="../assets/text.png" />文本
          </div>
          <div class="option" draggable="true" @click="onDrag('orcode')">
            <img src="../assets/orcode.png" ref="orcode" />条码
          </div>
          <div class="option" draggable="true" @click="onDrag('qrcode')">
            <img src="../assets/qrcode.png" ref="qrcode" />二维码
          </div>
          <!-- <div class="option" draggable="true" @click="onDrag('line')"> -->
          <!-- <img src="../assets/line.png" ref="line" />线条 -->
          <!-- </div> -->
        </div>
      </div>
      <!-- 内容区域 -->
      <div class="set_content">
        <div class="line flex">
          <div>输入内容</div>
          <div class="radio_group">
            <van-radio-group v-model="selDataWay" direction="horizontal">
              <van-radio name="1">手动输入</van-radio>
              <van-radio name="2" @click="dataClick">选择数据</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 手动输入数据 -->
        <div v-if="selDataWay === '1'" class="manual_input">
          <input
            type="text"
            placeholder="请输入"
            @blur="textChange"
            v-model="manual_input_text"
          />
        </div>
        <!-- 选择数据 -->
        <div v-if="selDataWay === '2'" class="flex_align_center">
          前缀
          <input
            class="input_cell"
            type="text"
            placeholder="请输入"
            @blur="prefixChange"
            v-model="prefix_input_text"
          />

          后缀
          <input
            class="input_cell"
            type="text"
            placeholder="请输入"
            @blur="suffixChange"
            v-model="suffix_input_text"
          />
        </div>
        <!-- 字体大小 -->
        <div class="line flex_align_center">
          <div>字体大小</div>
          <!-- <input
            type="text"
            placeholder="24"
            class="lab_input"
            @blur="fontSizeChange"
            v-model="canvasTextFontSize"
          /> -->
          <div class="font_size_slider">
            <van-slider
              @input="sizeChange()"
              v-model="canvasTextFontSize"
              active-color="rgb(57,124,248)"
            >
              <template #button>
                {{ canvasTextFontSize }}
              </template>
            </van-slider>
          </div>

          <div>
            <van-radio-group v-model="boldfont" direction="horizontal">
              是否加粗
              <van-radio name="1" @click="boldfontChange(true)">是</van-radio>
              <van-radio name="2" @click="boldfontChange(false)">否</van-radio>
            </van-radio-group>
          </div>
        </div>
        <!-- 坐标位置 -->
        <div class="line flex_align_center">
          <div>坐标位置</div>
          <div class="control_btn" @click="posClick('x-')">-</div>
          <input
            class="pos_input"
            type="div"
            placeholder="10"
            v-model="pos_x"
          />
          <div class="control_btn" @click="posClick('x+')">+</div>
          <div class="pos_unit">X</div>
          <div class="control_btn" @click="posClick('y-')">-</div>
          <input
            class="pos_input"
            type="div"
            placeholder="10"
            v-model="pos_y"
          />
          <div class="control_btn" @click="posClick('y+')">+</div>
        </div>
      </div>
    </div>
    <van-popup v-model="showCategory" position="bottom">
      <div class="category_title">请按顺序选择参数</div>
      <div
        class="category_list"
        v-for="(item, index) in categoryList"
        :key="item.name"
        @click="categoryClick(item, index)"
      >
        <div class="category_name">{{ item.name }}</div>

        <img v-if="item.isSel" src="../assets/sel.png" />
        <img v-else src="../assets/nosel.png" />
      </div>
      <div class="category_btn" @click="onConfirm">确定</div>
    </van-popup>
  </div>
</template>
<script>
import { fabric } from 'fabric'
import request from '../utils/request'
import wx from 'weixin-js-sdk' // 引入微信js-sdk
export default {
  data () {
    return {
      deleteIcon:
        "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='595.275px' height='595.275px' divBox='200 215 230 470' xml:space='preserve'%3E%3Ccircle style='fill:%23F44336;' cx='299.76' cy='439.067' r='218.516'/%3E%3Cg%3E%3Crect x='267.162' y='307.978' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)' style='fill:white;' width='65.545' height='262.18'/%3E%3Crect x='266.988' y='308.153' transform='matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)' style='fill:white;' width='65.544' height='262.179'/%3E%3C/g%3E%3C/svg%3E",
      template_width: 40,
      template_heith: 80,
      template_offx: 0,
      template_offy: 0,
      activeIndex: 0,
      templateName: '',
      selDataWay: '1', // 1 手动输入 2选择数据
      boldfont: '2', // 1加粗2不加粗
      currentType: '',
      canvasTextFontSize: 16,
      // labelOffx: 20,
      // labelOFfy: 20,
      manual_input_text: '请输入文本', // 手动输入文本
      selData_input_text: '', // 选择输入文本
      prefix_input_text: '', // 前缀
      suffix_input_text: '', // 后缀
      showCategory: false,
      token: '',
      id: '',
      imageUrl: '',
      shopID: '',
      canvasObjects: null,
      categoryList: [
        {
          name: '款号',
          isSel: false
        },
        {
          name: '名称',
          isSel: false
        },
        {
          name: '颜色/尺码',
          isSel: false
        },
        {
          name: '工厂直拼价',
          isSel: false
        },
        {
          name: '单独零售价',
          isSel: false
        },
        {
          name: '建议零售价',
          isSel: false
        },

        {
          name: '条码/二维码',
          isSel: false
        }
      ],
      categoryList_key: [],
      canvasScaleRate: 100,
      scale: 1,
      pos_x: 10,
      pos_y: 10,
      radio: '1',
      selCategoryName: ''
    }
  },
  created () {
    this.canvasObjects = []
    console.log('create')
    // this.token = this.$route.query.token
    this.token =
      this.getQueryString('token') || '465d4deb-c478-4058-8968-95e359ac27d7'
    this.id = this.getQueryString('id') || '99'
    console.log(this.token)
    if (this.id !== '0') {
      this.getCanvasData()
    }

    // fabric.Object.prototype.set({
    //   lockUniScaling: true
    // })
  },
  computed: {
    canvasStyle () {
      return {
        margin:
          this.template_offy * this.getOneMmsPx() +
          'px ' +
          this.template_offx * this.getOneMmsPx() +
          'px'
        // eslint-disable-next-line no-undef
      }
    }
  },

  methods: {
    exportImage () {
      // 动态设置背景色为白色
      this.canvas.setBackgroundColor('white', () => {
        this.canvas.renderAll() // 重新渲染 Canvas

        // 将 Canvas 导出为 Base64（带白色背景）
        this.imageUrl = this.canvas.toDataURL({
          format: 'png', // 导出为 PNG 格式
          quality: 1 // 图片质量
        })
        console.log('导出的图片 Base64 URL:', this.imageUrl)
      })
    },
    categoryClick (item, index) {
      console.log(item, index)
      this.categoryList.forEach(item => {
        item.isSel = false
      })

      this.categoryList[index].isSel = true
      this.selCategoryName = item.name
      if (item.name === '条码') {
        this.selCategoryName = '款号'
      }
    },
    // 获取每毫米的像素值
    getOneMmsPx () {
      // 创建一个1mm宽的元素插入到页面，然后坐等出结果
      const div = document.createElement('div')
      div.id = 'mm'
      div.style.width = '1mm'
      document.querySelector('body').appendChild(div)
      // 原生方法获取浏览器对元素的计算值
      const mm1 = document.getElementById('mm').getBoundingClientRect()
      console.log(mm1)
      return mm1.width
    },
    zoomIn () {
      this.scale += 0.1
      this.updateTransform()
    },
    zoomOut () {
      if (this.scale > 0.2) {
        this.scale -= 0.1
        this.updateTransform()
      }
    },
    updateTransform () {
      this.$refs.canvas_border.style.transform = `scale(${this.scale})`
    },
    // 设置画布缩放
    setzoom (isPlus) {
      let zoom = this.canvas.getZoom() // 获取画布当前缩放级别
      console.log('获取画布当前缩放级别')
      console.log(zoom)
      this.canvas.zoomToPoint(
        {
          // 关键点
          x: (this.template_width * this.getOneMmsPx()) / 2,
          y: (this.template_heith * this.getOneMmsPx()) / 2
        },
        zoom
      )
      console.log(this.template_width / 2, this.template_heith / 2)

      if (isPlus) {
        zoom += 0.1
        this.canvasScaleRate += 10
        this.zoomIn()
      } else {
        this.zoomOut()
        zoom -= 0.1
        this.canvasScaleRate -= 10
      }

      this.canvas.setZoom(zoom) // 设置画布缩放级别
    },
    async getCanvasData () {
      const res = await request({
        url: '/api/mch/Template/detail',
        method: 'post',
        headers: {
          'X-Access-Token': this.token
        },
        data: {
          id: this.id
        }
      })

      this.canvasObjects = this.htmlDecode(res.data.data.list)
      // this.canvasObjects = res.data.data.categoryList
      console.log(this.canvasObjects, 'canvasObjects')

      const temp = res.data.data
      this.templateName = temp.template_name
      this.template_width = temp.template_w
      this.template_heith = temp.template_h
      this.template_offx = temp.template_offx
      this.template_offy = temp.template_offy
    },
    // Decode解码
    htmlDecode: function (text) {
      // 1.首先动态创建一个容器标签元素，如DIV
      let temp = document.createElement('div')
      // 2.然后将要转换的字符串设置为这个元素的innerHTML(ie，火狐，google都支持)
      temp.innerHTML = text
      // 3.最后返回这个元素的innerText(ie支持)或者textContent(火狐，google支持)，即得到经过HTML解码的字符串了。
      const output = temp.innerText || temp.textContent
      temp = null
      return output
    },
    async saveTemplate () {
      // console.log(JSON.stringify(this.canvas))
      // console.log(this.canvas.toJSON(['categoryName', 'eleType', 'categoryId']))
      const list = JSON.stringify(
        this.canvas.toJSON(['categoryName', 'eleType'])
      )
      const jsonArr = this.canvas.toJSON([
        'categoryName',
        'eleType',
        'categoryId'
      ]).objects
      console.log(jsonArr)
      const arr = Array.from(jsonArr)

      // eslint-disable-next-line array-callback-return
      // arr.map(item => {
      //   console.log(item)
      // })

      console.log('排序前:', arr)
      for (let j = 0; j < arr.length - 1; j++) {
        // 这里 i < arr.length - 1 ，要思考思考合适吗？我们下面继续说
        for (let i = 0; i < arr.length - 1; i++) {
          if (arr[i].top > arr[i + 1].top) {
            const temp = arr[i]
            arr[i] = arr[i + 1]
            arr[i + 1] = temp
          }
        }
      }
      console.log('排序后:', arr)
      const resArr = []

      // eslint-disable-next-line array-callback-return
      arr.forEach(item => {
        if (parseInt(item.top) > 0 || parseInt(item.left) > 0) {
          resArr.push(item)
        }
      })
      console.log('最后', resArr)

      this.exportImage()

      // const imgUrl = this.$refs.canvas.toDataURL('image/png')
      // console.log(imgUrl)
      const res = await request({
        url:
          this.id === '0'
            ? '/api/mch/Template/add_template'
            : '/api/mch/Template/edit_template',
        method: 'post',
        headers: {
          'X-Access-Token': this.token
        },
        data: {
          token: this.token,
          template_name: this.templateName,
          template_w: this.template_width,
          template_h: this.template_heith,
          template_offx: this.template_offx,
          template_offy: this.template_offy,
          pic: this.imageUrl,
          list: list,
          id: this.id,
          categoryList: resArr,
          count: 0
        }
      })
      console.log(res)
      if (res.code !== 1) {
        wx.miniProgram.navigateBack({ url: '' })
      }
    },
    getQueryString: function (name) {
      const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
      const r = window.location.search.substr(1).match(reg)
      if (r != null) {
        return decodeURIComponent(r[2])
      }
      return null
    },
    boldfontChange (isBold) {
      const selectedObj = this.canvas.getActiveObject()
      selectedObj.fontWeight = isBold ? 'bold' : 'normal' // 将字体加粗
      this.canvas.requestRenderAll() // 刷新画布
    },
    sizeChange () {
      console.log('改变字体大小', this.canvasTextFontSize)
      const activeTxt = this.canvas.getActiveObject()
      console.log('activeTxt', activeTxt)
      const printDpi = 300 // 打印机 DPI
      const screenDpi = 96 // 屏幕 DPI
      const dpiRatio = printDpi / screenDpi
      // if (this.canvasTextFontSize <= 10) {
      //   activeTxt.set('fontSize', 10*10)
      // } else if (this.canvasTextFontSize <= 20) {
      //   activeTxt.set('fontSize', 20*10)
      // } else if (this.canvasTextFontSize <= 30) {
      //   activeTxt.set('fontSize', 25*10)
      // } else {
      //   activeTxt.set('fontSize', 30*10)
      // }
      // activeTxt.set()

      activeTxt.set('fontSize', this.canvasTextFontSize * 10)
      this.canvas.requestRenderAll() // 刷新画布
    },
    dataClick (value) {
      this.showCategory = true
    },
    updateCanvasText (key, value) {
      const selectedObj = this.canvas.getActiveObject()
      selectedObj.set(key, value)
      this.canvas.requestRenderAll() // 刷新画布
    },
    // 选择数据
    onConfirm () {
      const value = this.selCategoryName
      console.log(value + 'value')

      const selectedObj = this.canvas.getActiveObject()
      console.log(selectedObj)
      if (selectedObj.eleType === 'text') {
        selectedObj.set('text', value + ':' + '(' + value + ')')
      }
      selectedObj.set('categoryName', value)
      this.selData_input_text = value + ':' + '(' + value + ')'
      this.showCategory = false
      this.canvas.requestRenderAll() // 刷新画布
    },
    // 前缀
    prefixChange () {
      let charStr = ''
      if (this.selData_input_text) {
        charStr = this.prefix_input_text + this.selData_input_text
        if (this.suffix_input_text) {
          charStr = charStr + this.suffix_input_text
        }
      }
      this.updateCanvasText('text', charStr)
    },
    // 后缀
    suffixChange () {
      let charStr = ''
      if (this.selData_input_text) {
        charStr = this.selData_input_text + this.suffix_input_text
        if (this.prefix_input_text) {
          charStr = this.prefix_input_text + charStr
        }
      }
      this.updateCanvasText('text', charStr)
    },
    textChange () {
      this.updateCanvasText('text', this.manual_input_text)
    },
    // 坐标位置
    posClick (type) {
      const selectedObj = this.canvas.getActiveObject()
      // 移动元素
      console.log(this.pos_x)
      if (type === 'x-') {
        selectedObj.animate('left', `-=${this.pos_x}`, {
          onChange: this.canvas.renderAll.bind(this.canvas)
        })
      } else if (type === 'x+') {
        selectedObj.animate('left', `+=${this.pos_x}`, {
          onChange: this.canvas.renderAll.bind(this.canvas)
        })
      } else if (type === 'y-') {
        selectedObj.animate('top', `-=${this.pos_y}`, {
          onChange: this.canvas.renderAll.bind(this.canvas)
        })
      } else if (type === 'y+') {
        selectedObj.animate('top', `+=${this.pos_y}`, {
          onChange: this.canvas.renderAll.bind(this.canvas)
        })
      }
    },
    // 切换标签
    switchTab (index, title) {
      // 设置偏移位置

      this.showCategory = false
      console.log('switchTab', index, title)
      if (index === 1) {
        setTimeout(() => {
          if (this.canvas) {
            if (this.template_width || this.template_heith) {
              this.canvas.setDimensions({
                width: this.template_width * this.getOneMmsPx(),
                height: this.template_heith * this.getOneMmsPx()
              })
            }
          } else {
            this.initCanvas()
          }
        }, 1000)
      }
    },
    renderIcon (icon) {
      return function renderIcon (ctx, left, top, styleOverride, fabricObject) {
        const size = this.cornerSize
        ctx.save()
        ctx.translate(left, top)
        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle))
        ctx.drawImage(icon, -size / 2, -size / 2, size, size)
        ctx.restore()
      }
    },
    // 删除元素的方法
    deleteObject (eventData, transform) {
      const target = transform.target
      const canvas = target.canvas
      canvas.remove(target) // 删除元素
      canvas.requestRenderAll() // 刷新画布
    },
    createText (left, top) {
      const printDpi = 300 // 打印机 DPI
      const screenDpi = 96 // 屏幕 DPI
      const dpiRatio = printDpi / screenDpi

      // if (this.canvasTextFontSize <= 10) {
      //   this.canvasTextFontSize = 10
      // } else if (this.canvasTextFontSize <= 20) {
      //   this.canvasTextFontSize = 20
      // } else if (this.canvasTextFontSize <= 30) {
      //   this.canvasTextFontSize = 25
      // } else {
      //   this.canvasTextFontSize = 30
      // }

      const text = new fabric.IText(this.manual_input_text)

      text.left = left
      text.top = top
      text.eleType = 'text'
      text.fontWeight = this.boldfont === '1' ? 'bold' : 'normal'
      text.fontSize = this.canvasTextFontSize * 10
      this.canvas.add(text)
      this.addDelControl(text)
      text.setControlsVisibility({
        mt: false,
        mb: false,
        ml: false,
        mr: false,
        tr: false,
        tl: false,
        br: false,
        bl: false,
        mtr: false // 为true则middle-top-rotate控制器可用，false则不可用。
      })
      this.canvas.discardActiveObject()
      this.canvas.setActiveObject(text)
    },
    createOrCode (top, lef) {
      // const text = new fabric.Text('0123456789')
      // text.top = 0
      // text.left = 0
      // // text.originX = 'center'
      // // text.originY = 'top'
      // text.fontSize = 12
      const imgElement = this.$refs.orcode
      const printDpi = 300 // 打印机 DPI
      const screenDpi = 96 // 屏幕 DPI
      const dpiRatio = printDpi / screenDpi
      const image = new fabric.Image(this.$refs.orcode, {
        left: 0,
        top: 0,
        width: 100 * 2,
        height: 50,
        eleType: 'orcode'
        // categoryName: '',
        // cateogryID: 0,
        // text: ''
      })
      image.set({
        scaleX: 4,
        scaleY: 4
      })

      // const group = new fabric.Group([image, text])
      // group.top = 0
      // group.left = 0
      this.canvas.add(image)
      this.addDelControl(image)
      image.setControlsVisibility({
        mt: false,
        mb: false,
        ml: false,
        mr: false,
        tr: false,
        tl: false,
        br: false,
        bl: false,
        mtr: false // 为true则middle-top-rotate控制器可用，false则不可用。
      })
    },
    createqrcode (top, left) {
      const printDpi = 300 // 打印机 DPI
      const screenDpi = 96 // 屏幕 DPI
      const dpiRatio = printDpi / screenDpi
      const qrimage = new fabric.Image(this.$refs.qrcode, {
        left: 0,
        top: 0,
        width: 50 * 5,
        height: 50 * 5,
        eleType: 'qrcode'
      })

      qrimage.set({
        scaleX: 4,
        scaleY: 4
      })

      this.canvas.add(qrimage)
      this.addDelControl(qrimage)

      qrimage.setControlsVisibility({
        mt: true,
        mb: true,
        ml: true,
        mr: true,
        tr: true,
        tl: true,
        br: true,
        bl: true,
        mtr: true // 为true则middle-top-rotate控制器可用，false则不可用。
      })
    },
    createLine (top, left) {
      const line = new fabric.Line([50, 50, 200, 50], {
        stroke: 'black',
        strokeWidth: 2,
        originX: 'center',
        originY: 'center',
        eleType: 'line'
      })
      this.canvas.add(line)
      this.addDelControl(line)
      console.log('crdeline')
      line.setControlsVisibility({
        mt: false,
        mb: false,
        ml: false,
        mr: false,
        tr: false,
        tl: false,
        br: false,
        bl: false,
        mtr: false // 为true则middle-top-rotate控制器可用，false则不可用。
      })
    },
    // 添加删除控制器
    addDelControl (ele) {
      const delImg = new Image()
      delImg.src = this.deleteIcon
      ele.controls.deleteControl = new fabric.Control({
        x: 0.6,
        y: -0.6,
        offsetY: 0,
        offsetX: 0,
        cursorStyle: 'pointer', // 鼠标移到控件时的指针样式
        mouseUpHandler: this.deleteObject, // 鼠标抬起时触发的事件
        render: this.renderIcon(delImg),

        cornerSize: 30
      })
    },
    initCanvas () {
      console.log('create----canvas')
      const scale = window.devicePixelRatio // 获取设备像素比
      const printDpi = 300 // 打印机 DPI
      const screenDpi = 96 // 屏幕 DPI
      const dpiRatio = printDpi / screenDpi

      // 根据打印 DPI 调整 canvas 分辨率
      const canvasWidth = this.template_width * this.getOneMmsPx() * dpiRatio
      const canvasHeight = this.template_heith * this.getOneMmsPx() * dpiRatio

      // 创建画布
      this.canvas = new fabric.Canvas('canvas-box', {
        includeDefaultValues: false,
        width: canvasWidth,
        height: canvasHeight,
        backgroundColor: '#ffffff'
      })

      console.log('canvas' + this.canvas)

      if (this.canvasObjects !== null) {
        // 重绘制
        this.canvas.loadFromJSON(
          this.canvasObjects,
          this.canvas.renderAll.bind(this.canvas)
        )

        fabric.Object.prototype.setControlsVisibility({
          mt: false,
          mb: false,
          ml: false,
          mr: false,
          tl: false,
          br: false,
          bl: false,
          mtr: false // 为true则midd
        })
      }

      // 按下鼠标事件
      this.canvas.on('mouse:down', function (opt) {
        console.log('mouse:down', this.currentType)
        const evt = opt.e
        if (evt.altKey === true) {
          this.isDragging = true
          this.lastPosX = evt.clientX
          this.lastPosY = evt.clientY
        }
      })
    },
    onDrag (type) {
      this.currentType = type
      console.log('onDrag', this.currentType)

      if (type === 'text') {
        this.createText(10, 10)
      } else if (type === 'orcode') {
        this.createOrCode(10, 10)
      } else if (type === 'qrcode') {
        this.createqrcode(10, 10)
      } else if (type === 'line') {
        this.createLine(0, 0)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.category_title {
  font-size: 20px;
  padding: 10px 0;
}
.category_btn {
  padding: 10px 30px;
  border-radius: 6px;
  background-color: red;
  width: 60vw;
  color: white;
  margin: 0 auto;
}
.category_list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px;

  img {
    width: 24px;
    height: 24px;
  }
  .category_name {
    margin-right: 20px;
  }
}
.custom-button {
  width: 26px;
  color: #fff;
  font-size: 10px;
  line-height: 18px;
  text-align: center;
  background-color: rgb(57, 124, 248);
  border-radius: 100px;
}
.contain_view {
  padding: 10px 10px;

  .line {
    border-bottom: #999 1px solid;
  }
  .flex {
    display: flex;
  }
  .flex_just_center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .flex_align_center {
    display: flex;
    align-items: center;
  }
  .lab_input {
    width: 50px;
    margin: 10px 16px;
    border: transparent 1px solid;
  }
  .name_input {
    border: transparent 1px solid;
    margin: 0 10px;
  }
  .lab_unit {
    font-size: 30px;
    color: #0f1add;
    font-weight: 800;
    margin: 0 10px;
  }

  .head_btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .tab_card {
      flex: 1;
    }
  }
  .set_content {
    .font_size_slider {
      flex: 1;
    }
    margin: 10px 0;
    .radio_group {
      margin: 0 20px;
    }

    .manual_input {
      margin: 10px 0;
    }
    .input_cell {
      width: 100px;
      margin: 10px 10px;
    }
    .pos_input {
      width: 20px;
      margin: 10px 10px;
      border: transparent 1px solid;
    }
    .pos_unit {
      font-size: 20px;
      color: #0f1add;
      margin: 0 10px;
    }
    .control_btn {
      margin: 10px 4px;
      width: 30px;
      height: 30px;
      border: #777 solid 1px;
      border-radius: 4px;
      font-size: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .canvas_size {
    padding: 10px 0;
    .size_rate {
      margin: 0 20px;
    }
  }
  .layout_content {
    overflow: hidden;
    box-sizing: border-box;
    background-color: #999;
    // position: relative;
    // transform: scaleY(3);
    width: 300px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    .canvas_border {
      // margin: 0 auto;

      // transform: scale(2);

      .canvas {
        // transform: translate(-50%, -50%);
        margin: 0 auto;
        background-color: white;
      }

      // transform: translate(100, 100);
    }
    .option_btns {
      position: fixed;
      right: 0;
      background-color: white;
      width: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-right: 0 10px;
      font-size: 10px;

      .option {
        padding: 0 10px;
        cursor: pointer;
        border-bottom: #777 solid 1px;
      }
      img {
        width: 20px;
        height: 20px;
      }
    }
  }
}
</style>
